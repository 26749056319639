import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Lang } from '@common/typescript/objects/Lang';

import { Doctor } from '@commonTuna/react/objects/BaseDoctor';

import Select from '@app/components/UI/BseSelect';

interface DoctorSelectProps {
	items: Array<Doctor>;
	language: Lang;
	form: any;
	field: any;
	atModal?: boolean;
}

const DoctorSelect: React.FC<DoctorSelectProps> = ({
	items,
	language,
	form,
	field,
	atModal,
}) => {
	const { t } = useTranslation();
	return <Select
		atModal={atModal}
		placeholder={t('forms.Select doctor')}
		aria-label={t('forms.Select doctor')}
		value={field.value > 0 ? field.value : null}
		onChange={(value: any) => form.setFieldValue(field.name, +value?.value)}
		options={items.map((doctor) => ({
			value: doctor.id,
			label: language === Lang.Es ? doctor.nameEs : doctor.nameEn,
		}))}
	/>;
};

export default DoctorSelect;
