export enum TypeOfCoverage {
	MedicarePartB = 0,
	Medicaid = 1,
	Hmo = 2,
	Champus = 3,
	PreferredProviderOrganization = 4,
	FederalEmployeeProgram = 5,
	Commercial = 6,
	MedicarePartA = 7,
	HmoMedicareRisk = 8,
	PointOfService = 9,
	ExclusiveProviderOrganization = 10,
	IndemnityInsurance = 11,
	DentalMaintenanceOrganization = 12,
	AutomobileMedical = 13,
	BlueCrossBlueShield = 14,
	Disability = 15,
	OtherNonFederalProgram = 16,
	LiabilityMedical = 17,
	OtherFederalProgram = 18,
	TitleV = 19,
	VeteransAffairsPlan = 20,
	WorkersCompensation = 21,
	MutuallyDefined = 22,
}

export const TypeOfCoverageNames = {
	[TypeOfCoverage.MedicarePartA]: 'MEDICARE PART A',
	[TypeOfCoverage.MedicarePartB]: 'MEDICARE PART B',
	[TypeOfCoverage.Medicaid]: 'MEDICAID',
	[TypeOfCoverage.Hmo]: 'Health Maintenance Organization (HMO)',
	[TypeOfCoverage.Champus]: 'CHAMPUS',
	[TypeOfCoverage.PreferredProviderOrganization]: 'Preferred Provider Organization (PPO)',
	[TypeOfCoverage.FederalEmployeeProgram]: 'Federal Employees Program',
	[TypeOfCoverage.Commercial]: 'COMMERCIAL',
	[TypeOfCoverage.HmoMedicareRisk]: 'Health Maintenance Organization (HMO) Medicare Risk',
	[TypeOfCoverage.PointOfService]: 'Point of Service (POS)',
	[TypeOfCoverage.ExclusiveProviderOrganization]: 'Exclusive Provider Organization (EPO)',
	[TypeOfCoverage.IndemnityInsurance]: 'Indemnity Insurance',
	[TypeOfCoverage.DentalMaintenanceOrganization]: 'Dental Maintenance Organization',
	[TypeOfCoverage.AutomobileMedical]: 'Automobile Medical',
	[TypeOfCoverage.BlueCrossBlueShield]: 'Blue Cross/Blue Shield',
	[TypeOfCoverage.Disability]: 'Disability',
	[TypeOfCoverage.OtherNonFederalProgram]: 'Other Non-Federal Programs',
	[TypeOfCoverage.LiabilityMedical]: 'Liability Medical',
	[TypeOfCoverage.OtherFederalProgram]: 'Other Federal Program',
	[TypeOfCoverage.TitleV]: 'Title V',
	[TypeOfCoverage.VeteransAffairsPlan]: 'Veterans Affairs Plan',
	[TypeOfCoverage.WorkersCompensation]: 'Workers\' Compensation Health Claim',
	[TypeOfCoverage.MutuallyDefined]: 'Mutually Defined',
};

export enum Gender {
	Male = 0,
	Female = 1,
	Other = 2,
	None = 3
}

export const GenderNames = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.Other]: 'Other',
	[Gender.None]: 'None',
};

export enum AvailableNumbersSearchType {
	StartsWith = 0,
	Anywhere = 1,
	EndsWith = 2
}

export const AvailableNumbersSearchTypeNames = {
	[AvailableNumbersSearchType.Anywhere]: 'Anywhere',
	[AvailableNumbersSearchType.StartsWith]: 'Starts with',
	[AvailableNumbersSearchType.EndsWith]: 'Ends with',
};

export enum NotificationsType {
	Sms,
	Email,
	SmsAndEmail,
	None
}

export const NotificationTypeNames = {
	[NotificationsType.Sms]: 'Sms',
	[NotificationsType.Email]: 'Email',
	[NotificationsType.SmsAndEmail]: 'Sms & Email',
	[NotificationsType.None]: 'None',
};

export enum MailboxType {
	Smtp = 0,
	Imap = 1
}

export enum PublishedFilterEnum {
	All = 0,
	Published = 1,
	Unpublished = 2
}

export const publishedFilterGroup = [
	{ caption: 'Published', value: PublishedFilterEnum.Published },
	{ caption: 'Unpublished', value: PublishedFilterEnum.Unpublished },
	{ caption: 'All', value: PublishedFilterEnum.All },
];
