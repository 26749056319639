import * as React from 'react';

export const useMobileView = (
	size = 520,
	onResize: (setIsMobile: React.Dispatch<React.SetStateAction<boolean>>) => void = (setIsMobile) => {
		setIsMobile(window.innerWidth < size);
	},
) => {
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const resize = () => {
			onResize(setIsMobile);
		};

		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	return isMobile;
};
