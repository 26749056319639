import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

import { Lang } from '@common/typescript/objects/Lang';
import useRequest from '@common/react/hooks/useRequest';
import { List } from '@common/typescript/objects/List';

import { Page } from '@commonTuna/react/objects/Page';

import { getPageShortName, getLangByName } from '@app/components/Utils';

const HeaderSearch: React.FC = () => {
	const [searchVisible, changeSearchVisibility] = React.useState<boolean>(true);
	const request = useRequest();
	const [value, setValue] = React.useState();
	const [initLoad, setInitLoad] = React.useState(true);
	const [loading, setLoading] = React.useState(false);
	const [defaultOptions, setDefaultOptions] = React.useState([]);

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const lang = getLangByName(i18n.language);

	const loadOptions = (inputValue: string, callback: (options) => void) => {
		request<List<any>>('serviceListRemote', { text: inputValue })
			.then((res) => {
				callback(res.list.map((autocompleteItem: Page) => {
					const name = getPageShortName(autocompleteItem, lang);

					return {
						title: name,
						value: (lang === Lang.Es ? autocompleteItem.shortNameEs : autocompleteItem.shortNameEn) || '',
						label: (lang === Lang.Es ? autocompleteItem.shortNameEs : autocompleteItem.shortNameEn) || '',
						item: autocompleteItem,
					};
				}));
			});
	};

	const onFocus = () => {
		if (initLoad && !loading) {
			setLoading(true);

			loadOptions('', (list) => {
				setDefaultOptions(list);
				setLoading(false);
				setInitLoad(false);
			});
		}
	};

	return <div className={`header-search-component ${searchVisible ? 'header-search-component_open' : ''}`}>
		<AsyncSelect
			classNames={{
				menu: () => 'general-form-select-dropdown',
				clearIndicator: () => 'autocomplete-component-clear',
				dropdownIndicator: () => 'autocomplete-component-dropdown-indicator',
				control: () => 'autocomplete-component-control',
				input: () => 'autocomplete-component-input',
				placeholder: () => 'autocomplete-component-placeholder',
				indicatorSeparator: () => 'autocomplete-component-indicatorSeparator',
				noOptionsMessage: () => 'autocomplete-component-empty',
				option: () => 'autocomplete-component-option',
				singleValue: () => 'autocomplete-component-value',
			}}
			id="header-select"
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: 'rgba(255, 255, 255, 0.3)',
					primary: 'rgba(238, 174, 21, 0.5)',
				},
			})}
			defaultOptions={defaultOptions}
			value={value}
			className="autocomplete-component"
			placeholder={t('forms.search')}
			cacheOptions
			loadOptions={loadOptions}
			isClearable
			isLoading={loading}
			onFocus={onFocus}
			onChange={(value: any) => {
				setValue(value);
				if (value?.item) {
					navigate(`/${(lang === Lang.Es ? `es/${value.item.fullPathEs}` : value.item.fullPath)}`);
				}
			}}
		/>
		<i className="fa fa-search header-search-component__button" />
	</div>;
};

export default HeaderSearch;
