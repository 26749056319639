import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// eslint-disable-next-line
import once from 'lodash/once';
// eslint-disable-next-line
import isEmpty from 'lodash/isEmpty';

import { Company } from '@commonTuna/react/objects/Company';
import { Location } from '@commonTuna/react/objects/BaseLocation';
import { Doctor } from '@commonTuna/react/objects/BaseDoctor';
import { Page } from '@commonTuna/react/objects/Page';

import { ApplicationState } from '@app/store';

export interface ReduxContext {
	companySettings: Company;
	offices: Array<Location>;
	menu: Array<Page>;
	initDoctors: Array<Doctor>;
}

export const createReduxContext = once(() => React.createContext({} as ReduxContext));

export const useReduxContext: () => ReduxContext = () => {
	const context: ReduxContext = React.useContext(createReduxContext());

	if (isEmpty(context)) throw 'Need Redux Context!';

	return context;
};

const ReduxProvider: React.FC = ({
	children,
}) => {
	const ReduxProvider = createReduxContext();

	const value = useSelector((state: ApplicationState) => ({
		companySettings: state.companySettings.item,
		offices: state.offices.items,
		menu: state.menu.items,
		initDoctors: state.initDoctors.items,
	}), shallowEqual);

	return (
		<>
			<ReduxProvider.Provider
				value={value}
			>
				{typeof children === 'function' ? children(value) : children}
			</ReduxProvider.Provider>
		</>
	);
};

export default ReduxProvider;
