import * as React from 'react';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import '@common/react/yupLocaleSettings';
import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import { NotFoundPageProvider } from '@common/react/components/Core/NotFoundPageProvider/NotFoundPageProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import Application from '@common/react/components/Core/Application/Application';
import BaseModalContextProvider from '@common/react/components/base/BaseModalContextProvider/BaseModalContextProvider';

import '@app/scss/style.scss';
import '@app/scss/vendor.scss';
import TranslatedLoading from '@app/components/UI/TranslatedLoading/TranslatedLoading';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import ReduxProvider from '@app/hooks/useReduxContext';
import { User } from '@app/objects/User';
import { Init } from '@app/objects/Init';
import InitI18n from '@app/components/InitLang';

const paramsTranslated = {
	fallback: <Loader />,
};

const CustomNotFoundPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CustomNotFoundPage" */ '@app/components/Pages/NotFoundPage/NotFoundPage')), paramsTranslated);

const Layout: React.FC = ({ children }) => <div className="app-container">
	<ErrorBoundaryWithLocation>
		<ReduxProvider>
			{({ companySettings: { googleAnalyticsId } }) =>
				<RouteChangeTracker id={googleAnalyticsId}>
					<RequestProvider ttl={120000} errorComponents={{ notFound: () => <CustomNotFoundPage /> }}>
						<Application<User, Init> withoutSignalR>
							<InitI18n>
								<NotFoundPageProvider notFoundComponent={(() => <CustomNotFoundPage />) as any}>
									<LoadingProvider loader={<TranslatedLoading />}>
										<BaseModalContextProvider>
											{children}
										</BaseModalContextProvider>
									</LoadingProvider>
								</NotFoundPageProvider>
							</InitI18n>
						</Application>
					</RequestProvider>
				</RouteChangeTracker>
			}
		</ReduxProvider>
	</ErrorBoundaryWithLocation>
</div>;

export default Layout;
