import * as React from 'react';

import Modal from '@common/react/components/base/Modal/Modal';

import {
	ModalContextProvider,
} from '@common/react/components/Modal/ModalContextProvider';
import Messages, { MessageType } from '@common/react/components/base/Messages/Messages';

interface ModalComponentProps {
	visible: boolean;
	props: any;
	close: () => void;
	beforeTitle: React.ReactNode;
	renderButtons: () => React.ReactNode;
	defaultTitle?: string;
	defaultContent?: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
	visible,
	props,
	beforeTitle,
	renderButtons,
	close,
	defaultTitle = 'Error',
	defaultContent = 'Something went wrong',
}) => {
	const ref = React.useRef<any>(null);
	const {
		title = defaultTitle,
		message,
		content = message ?? defaultContent,
	} = props;
	React.useEffect(() => {
		ref.current && ref.current.update({
			...props,
			content: props.message ?? props.content,
			afterClose: () => {
				props.afterClose?.();
				close();
			},
		});
	}, [props, close]);

	return <Modal
		{...props}
		onClose={() => {
			props.afterClose?.();
			close();
		}}
		open={visible}
	>
		<h3>
			{beforeTitle}
			{title}
		</h3>
		<p>
			{content}
		</p>
		<div className="text-end">
			{renderButtons()}
		</div>
	</Modal>;
};

export const AntdModalContextProvider: React.FC = ({
	children,
}) => {
	return (
		<Messages
			render={(addMessage) => <ModalContextProvider
				openErrorMessage={(message) => addMessage(message, { type: MessageType.Error })}
				openSuccessMessage={(message) => addMessage(message, { type: MessageType.Success })}
				renderModal={(visible, close, props) => {
					return <Modal
						{...props}
						onClose={() => {
							props.afterClose?.();
							close();
						}}
						open={visible}
					/>;
				}}
				renderError={(visible, close, props) => {
					const {
						cancelText = 'Cancel',
						cancelButtonProps = {},
					} = props;
					return <ModalComponent
						visible={visible}
						close={close}
						props={props}
						renderButtons={() => <button
							className="btn btn-default"
							{...cancelButtonProps}
							type="button"
							onClick={() => (props.onCancel || close)()}
						>
							{cancelText}
						</button>}
						beforeTitle={<i className="fa fa-warning error-icon mr10" style={{ color: 'red' }} />}
					/>;
				}}
				renderSuccess={(visible, close, props) => {
					const {
						cancelText = 'Ok',
						cancelButtonProps = {},
					} = props;
					return <ModalComponent
						visible={visible}
						close={close}
						props={props}
						defaultContent=""
						defaultTitle="Success"
						renderButtons={() => <button
							className="btn btn-default"
							{...cancelButtonProps}
							type="button"
							onClick={() => (props.onCancel || close)()}
						>
							{cancelText}
						</button>}
						beforeTitle=""
					/>;
				}}
				renderAlert={(visible, close, props: any) => {
					const { onOk } = props;
					return <ModalComponent
						visible={visible}
						close={close}
						props={props}
						defaultContent=""
						defaultTitle="Alert"
						renderButtons={() => <button
							type="button"
							className="btn btn-default"
							onClick={() => (onOk || close)()}
						>
							Ok
						</button>}
						beforeTitle=""
					/>;
				}}
				renderDeleteConfirm={(visible, close, props: any) => {
					const {
						onOk,
						onCancel,
						cancelText = 'Cancel',
						okText = 'Yes',
						cancelButtonProps = {},
						okButtonProps = {},
					} = props;
					return <ModalComponent
						visible={visible}
						close={close}
						props={props}
						defaultContent=""
						defaultTitle="This item will be permanently deleted and cannot be recovered. Are you sure?"
						renderButtons={() => <>
							<button
								className="btn btn-default"
								{...okButtonProps}
								type="button"
								onClick={() => (onOk || close)()}
							>
								{okText}
							</button>
							<button
								className="btn btn-danger"
								{...cancelButtonProps}
								type="button"
								onClick={() => (onCancel || close)()}
							>
								{cancelText}
							</button>
						</>}
						beforeTitle=""
					/>;
				}}
				renderConfirm={(visible, close, props: any) => {
					const {
						onOk,
						onCancel,
						cancelText = 'Cancel',
						okText = 'Ok',
						cancelButtonProps = {},
						okButtonProps = {},
					} = props;
					return <ModalComponent
						visible={visible}
						close={close}
						props={props}
						defaultContent=""
						defaultTitle="Alert"
						renderButtons={() => <>
							<button
								className="btn btn-default"
								{...okButtonProps}
								type="button"
								onClick={() => (onOk || close)()}
							>
								{okText}
							</button>
							<button
								className="btn btn-primary"
								{...cancelButtonProps}
								type="button"
								onClick={() => (onCancel || close)()}
							>
								{cancelText}
							</button>
						</>}
						beforeTitle=""
					/>;
				}}
			>
				{children}
			</ModalContextProvider>
			}
		/>
	);
};

export default AntdModalContextProvider;
