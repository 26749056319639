import React from 'react';

interface RootMargin {
	rootMargin?: string | undefined;
}

type Props = React.ImgHTMLAttributes<HTMLImageElement> & RootMargin

const ImageLazy: React.FC<Props> = ({ src, rootMargin = '15px', ...props }) => {
	const ref = React.useRef<HTMLImageElement | null>(null);
	const [visible, setVisible] = React.useState(false);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries.some((entry) => entry.isIntersecting)) {
					setVisible(true);
				}
			}, {
				rootMargin,
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, []);

	return <img
		ref={ref}
		src={visible ? src : ''}
		{...props}
		onError={visible ? props.onError : undefined}
		alt={props.alt}
	/>;
};

export default ImageLazy;
