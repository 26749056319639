/**
 * ## ItemsProvider.tsx ##
 * This file contains ItemsProvider component
 * @packageDocumentation
 */

import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import once from 'lodash/once';
import { AnySchema, ValidationError } from 'yup';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import {
	addPrefix,
	getKeysByPrefix,
} from '@common/react/utils/ObjectKeysPrefix/objectKeysPrefix';
import { ClearValue, generateGUID } from '@common/react/utils/utils';

import { List } from '@common/typescript/objects/List';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { handleUrl, getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import useRequest from '@common/react/hooks/useRequest';
import { Nullable } from '@common/typescript/objects/Nullable';
import { useRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';
import useAbortController from '@common/react/hooks/useAbortController';
import { useModal } from '@common/react/components/Modal/ModalContextProvider';

export enum Mode {
	View,
	Edit
}

export enum SortingDirection {
	Default = 0,
	Ascending = 1,
	Descending = 2
}

/**
 * used to define sorting
 */
export interface ColumnFilter {
	caption: string;
	direction: SortingDirection;
}

export interface WithKey extends WithDeleted {
	keyGUID?: string;
}

interface Edits<T extends WithKey> {
	[key: string]: T;
}

interface Loaders {
	[key: string]: boolean;
}

interface Errors {
	[key: string]: {
		err: BaseParams,
		submitCount: number;
	};
}

interface FiltersRef {
	filters: BaseParams;
	silent?: boolean;
	concatResult?: boolean;
	resetFilters?: boolean;
}

/**
 * defines pagination
 */
export interface PaginationState {
	/**
	 * Current page number
	 */
	current: number;
	/**
	 * Number of data items per page
	 */
	pageSize: number;
	/**
	 * Total number of data items
	 */
	total: number;
}

export type PaginationOptions = Partial<PaginationState>

/**
 * type of second argument RenderItem
 */
export interface RenderItemProps<T> {
	/**
	 * item loading state
	 */
	loading: boolean;
	/**
	 * callback to save the passed element
	 * @param item - data that will replace the element and will be sent in the request
	 * @param saveRequest - custom request name, if you need a request different from the request in ItemsProvider
	 */
	save: (item?: T, saveRequest?: string) => void;
	/**
	 * update the current element without request
	 * @param item - partial data that will replace the value in the element
	 */
	update: (item: Partial<T>) => void;
	/**
	 * callback to update and save the current element
	 * @param item - partial data that will replace the value in the element before sending the request
	 * @param saveRequest - custom request name, if you need a request different from the request in ItemsProvider
	 */
	updateAndSave: (item: Partial<T>, saveRequest?: string) => void;
	/**
	 * callback to reload ItemsProvider data
	 * @param pagination - new pagination options
	 * @param resetFilters - determines whether filters need to be reset to default
	 */
	reload: (p: Partial<PaginationState>, resetFilters?: boolean | undefined) => Promise<List<T>>;
	/**
	 * callback to add new items at ItemsProvider
	 * @param item - new item data
	 */
	add: (item?: Partial<T>) => void;
}

type StringBoolNumber = string | boolean | number;

/**
 * filter value change object
 */
export interface HandleChangeEventElement {
	currentTarget: {
		name: string;
		value: Nullable<StringBoolNumber | Array<StringBoolNumber>>;
	};
}

/**
 * @typeParam T - T Any WithKey entity
 * This is function type. Used to render the list element.
 * The function accepts tho argument - item and props.
 * item - The element for which the render is called.
 * props - The element state and action parameters
 */
export type RenderItem<T extends WithKey> = (item: T, props: RenderItemProps<T>) => React.ReactNode;

type LikeList<T extends WithKey> = List<T>

/**
 * This is the description of the interface
 *
 * @interface ItemsProviderProps
 * @typeParam T - T Any WithKey entity
 */
export interface ItemsProviderProps<T extends WithKey, Other = BaseParams> {
	/**
	 * determines by default which elements to load and how to save them
	 */
	type: string;
	/**
	 * - 1. ReactElement to be wrapped in an ItemsProvider context
	 * - 2. function with context ItemsProvider as first argument
	 */
	children?: React.ReactNode | ((context: ItemsProviderContext<T>) => React.ReactNode);
	/**
	 * default ItemsProvider elements.
	 * @default []
	 */
	items?: Array<T>;
	/**
	 * default _OtherData value.
	 * _OtherData is used when a load request returns something other than count, execution, offset and list.
	 * Stores this data
	 */
	otherData?: Other;
	/**
	 * default filters
	 */
	filters?: BaseParams;
	/**
	 * load request name. The default is made up of type.
	 */
	loadRequest?: string;
	/**
	 * save request name. The default equal type.
	 * @default type
	 */
	saveRequest?: string;
	/**
	 * save request name.
	 * @default ''
	 */
	saveAllRequest?: string;
	/**
	 * default pagination value
	 */
	pagination?: PaginationOptions;
	/**
	 * add - used when add from a context is called without arguments. Sets default values for a new element
	 * @param items - current items in ItemsProvider state. items can be useful when you need to put an index for a new element
	 * @returns T - new ItemsProvider element
	 */
	add?: (items: Array<T>) => T;
	/**
	 * defines whether it is allowed to edit several elements at the same time
	 */
	multiple?: boolean;
	/**
	 * schema for checking elements before saving.
	 */
	validationSchema?: AnySchema;
	/**
	 * transform item before send to server
	 */
	clearForSubmit?: (item: T) => ClearValue<T> | T;
	/**
	 * error handling function for all requests
	 * @param error - error text
	 */
	onRequestError?: ((error: string) => void);
	/**
	 * error handling function for load request and will be used instead of onRequestError
	 * @param error - error text
	 */
	onLoadRequestError?: ((error: string) => void);
	/**
	 * error handling function for save, saveAll and deleteAll requests and will be used instead of onRequestError
	 * @param error - error text
	 */
	onSaveRequestError?: ((error: string) => void);
	/**
	 * Validation error handling function
	 */
	onValidationError?: ((key, err, error: Error) => void);
	mode?: Mode;
	/**
	 * key to store the functions of the child Item Provider
	 */
	objectKey?: string;
	/**
	 * object key to save results after calling a function from ref
	 */
	arrayName?: string;
	/**
	 * load callback
	 * @param res - request result
	 */
	onLoad?: (res: List<T> & Other) => void;
	/**
	 * function to convert items after load
	 * @param res - request result
	 * @param filters - current filters
	 */
	transformItems?: (res: Array<T>, filters: BaseParams) => Array<T>;
	/**
	 * filters that will not be put in the url. These filters are expected to be fixed
	 */
	unhandledFilters?: BaseParams;
	/**
	 * A parameter that determines whether to add filters to the url
	 */
	withHandleUrl?: boolean;
	/**
	 * filter processing before putting in url.
	 */
	transformFiltersBeforeHandleUrl?: (filters) => BaseParams;
	/**
	 * url pathname
	 */
	path?: string;
	filtersPrefix?: string;
	/**
	 * how handle page in url.
	 * `pathname/${page}` or `${pathname}?page=${page}`
	 * @default false
	 */
	pageInSearch?: boolean;
	/**
	 * add new element as first or end
	 * @default false
	 */
	addedFirst?: boolean;
	/**
	 * will set that filters if load called by resetFilters flag
	 */
	defaultFilters?: BaseParams;
	/**
	 * callback used after load if resetFilters is true
	 */
	afterResetFilters?: (filters?: BaseParams) => void; // for clear autocompletes or other filters
	/**
	 * default edit items
	 */
	editItems?: Edits<T>;
	/**
	 * that items will set in itemsProvider state
	 * used as dependencies in useEffect
	 */
	syncItems?: Array<T>;
	/**
	 * wait in ms before sending a request
	 */
	delay?: number; // ms
	/**
	 * callback. Called when items changed inside ItemsProvider
	 */
	onItemsChange?: (items: Array<T>, filters?: BaseParams, res?: List<T>) => void;
	skipValidationAll?: boolean;
	/**
	 * render one item
	 */
	render?: RenderItem<T>;
	/**
	 * callback. Called before save one item
	 */
	beforeSave?: (item: Array<T>, oldItem: Array<T>, callSave: () => any, loaders: (loading) => void) => any;
	/**
	 * callback. Called after save one item
	 */
	onSave?: (item: T, response?: T) => void;
	/**
	 * callback. Called after save all edit items. After receiving a response, reloads the data
	 */
	onSaveAll?: (items: Array<T>, response: any) => void;
	/**
	 * transform items after save
	 */
	transformAfterSave?: (prevItem: T, editedItem: T, response: T) => T;
	/**
	 * init load option
	 */
	skipInitLoad?: boolean;
	/**
	 * get parameters from url
	 */
	searchParamsFromUrl?: (location, prefix?: string) => BaseParams;
	/**
	 * delete all request name. filters object is used as request parameter
	 * @default ''
	 */
	deleteAllRequest?: string;
	/**
	 * time to live (ms) for cached response at RequestProvider if cache is available
	 */
	ttl?: number;
	/**
	 * use when the response does not return a list. For example, we can convert an array to a list.
	 *
	 * - (res: Array<T>) => ({ list: res, offset: 0, execution: 0, offset: 0 })
	 *
	 * or we can solve some value and put it in otherData. fields from the returned object will be placed in
	 * otherData, excluding offset, execution, list, count. otherData type is Omit<LikeList<T>, 'list' | 'count' | 'execution' | 'offset'>
	 *
	 * - (res: Array<T>) => ({ list: res, count: res.length, execution: 0, offset: 0 })
	 */
	transformResponse?: (res: any, filters: BaseParams) => LikeList<T>;
	/**
	 * if the flag is true syncItems will be used as items directly without using useEffect
	 */
	useSyncItemsInsteadHook?: boolean;
	/**
	 * slice loaded list if count > pageSize. By default true
	 */
	sliceResultListByPageSize?: boolean;
	/**
	 * if true, then handleChange will send a request every time it is called.
	 *
	 * if false, only filters will be changed without sending a request
	 *
	 * used as default value for the second argument in handleChange
	 *
	 * By default true
	 */
	sendRequestAtHandleChange?: boolean;
}

/**
 * This is the description of the interface
 *
 * @interface ItemsProviderContextState
 * @typeParam T - T Any WithKey entity
 */
export interface ItemsProviderContextState<T extends WithKey, Other = BaseParams> {
	items: Array<T>;
	/**
	 * ItemsProvider loading state
	 */
	loading: boolean;
	/**
	 * ItemsProvider pagination
	 */
	pagination: PaginationState;
	filters: BaseParams;
	/**
	 * stores edit items, key is item id. if item is new id < 0
	 */
	edits: Edits<T>;
	/**
	 * stores items validation errors and submit count
	 */
	errors: Errors;
	loaders: Loaders;
	/**
	 * defines whether it is allowed to edit several elements at the same time
	 */
	multiple: boolean;
	type: string;
	selectedRows: Array<T>;
	/**
	 * OtherData is used when a load request returns something other than count, execution, offset and list.
	 */
	otherData: Other;
	/**
	 * add new element as first or end. By default false
	 */
	addedFirst?: boolean;
	/**
	 * if wrapped by AdvancedItemsProvider
	 */
	advancedItems?: Array<T>;
	/**
	 * transform items after save
	 */
	transformAfterSave: (prevItem: T, editedItem: T, response: T) => T;
	/**
	 * link to prevent reload from being called. For example, after deleting an element, if elements are updated from notifications
	 */
	deleting: React.MutableRefObject<boolean>;
	/**
	 * delete all request name. The default is ''.
	 */
	deleteAllRequest?: string;
	/**
	 * error message
	 */
	error: string;
	/**
	 * save all request name.
	 */
	saveAllRequest?: string;
}

export interface ItemsProviderContextActions<T extends WithDeleted> {
	/**
	 * load new items for ItemsProvider
	 * @param data           - Loading options such as filters, page number and number of items per page
	 * @param silent         - If true load new items without change 'loading' state
	 *                         #for example if we want to update an element without showing a loading indicator
	 * @param concatResult   - Defines whether to replace old elements with new ones or add them to old ones.
	 *                         #used in loadMore function
	 * @param resetFilters   - Determines whether filters should be reset to default or not
	 * @param reverseResult  - If true work as Array.reverse(items)
	 * @param useResult      - {use: boolean}. If need prevent setState after load, change use to false
	 *                         # change as reference useResult.use = false
	 * @param edits          - edits after save
	 * @return Promise<List<T>>
	 */
	load: (
		data?: LoadData,
		silent?: boolean,
		concatResult?: boolean,
		resetFilters?: boolean,
		reverseResult?: boolean,
		useResult?: { use: boolean },
		edits?: Edits<T>
	) => Promise<List<T>>;
	/**
	 * save item
	 * @param record         - data sent in the request
	 * @param skipValidation - ignore validation or no. By default is undefined
	 * @param saveRequest    - requestName. By default used saveRequest from ItemsProvider props
	 * @return Promise<List<T>>
	 */
	save: (record, skipValidation?: boolean, saveRequest?: string) => Promise<T>;
	/**
	 * save all items
	 * @param skipValidation - ignore validation or no. By default is undefined
	 */
	saveAll: (skipValidation?: boolean, reloadHandler?: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>>) => Promise<void> | undefined;
	/**
	 * update items
	 * @param items - set that items in ItemsProvider state
	 * @param resetEdits - reset edits or not. default true
	 */
	update: (items: Array<T>, resetEdits?: boolean) => void;
	/**
	 * add a new element to both items and edits. returns a new element. can be used to save
	 * - The id of the new element will be replaced with generated temporary negative id, so for adding already saved item need to use setItems
	 * @param item - set that item in ItemsProvider state. default value is determined from add props
	 * @returns newItem
	 */
	add: (item?: Partial<T>) => T;
	/**
	 * function to change the state of edits in ItemsProvider. from the function you can set which elements are edited and what their values are.
	 */
	setEdits: React.Dispatch<React.SetStateAction<Edits<T>>>;
	setErrors: React.Dispatch<React.SetStateAction<Errors>>;
	/**
	 * reload items.
	 * @param p             - load params. By default use current page and pageSize.
	 * @param resetFilters  - Determines whether filters should be reset to default or not
	 */
	reload: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>>;
	/**
	 * add new or update item in edits.
	 * - edits dictionary usually using to store elements in edit mode, so by this function you can set which element is being
	 * edited and what its value is.
	 * @param record        - item to be edited
	 */
	setEdit: (record: T) => void;
	/**
	 * callback to update items
	 * @param items - new items value
	 * @param resetEdits - reset edits or not. default true
	 */
	setItems: (items: Array<T> | ((items: Array<T>) => Array<T>), resetEdits?: boolean) => void;
	addRef: (key: string | number, f) => void;
	/**
	 * callback to update items
	 * @param items - new items value
	 */
	setSelectedRows: React.Dispatch<React.SetStateAction<Array<T>>>;
	/**
	 * callback to remove passed elements
	 * @param items - values to be removed
	 * @param reloadHandler - used to prevent reload if need. by default used 'reload' function
	 */
	deleteItems: (items: Array<T>, reloadHandler?: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>>) => Promise<void>;
	/**
	 * behavior depends on second argument (default value is sendRequestAtHandleChange from props).
	 *
	 * if true:
	 *
	 * load new items and change filters
	 *
	 * if false:
	 *
	 * will change filters without sending a request
	 *
	 * Processes params before calling load if HandleChangeEventElement is passed
	 * This callback function handles filters returning HandleChangeEventElement (for example SimpleSearchInput or SelectFilter).
	 * If you use load as props for this filter without processing, an error will occur.
	 *
	 * @param params        - load filters or HandleChangeEventElement
	 * @param sendRequest   - determines whether the request will be sent
	 */
	handleChange: (params?: BaseParams, sendRequest?) => Promise<List<T> | undefined>;
	/**
	 * load new items and concat them with previous items
	 * @param data          - load filters
	 * @param reverseResult - If true work as Array.reverse(items)
	 * @param silent        - If true load new items without change 'loading' state
	 */
	loadMore: (data?: LoadData, reverseResult?: boolean, silent?: boolean) => Promise<List<T>>;
	/**
	 * save all edit items
	 * @param items         - items to be sent in the request
	 * @param reloadHandler - used to prevent reload if need. by default used 'reload' function
	 * @param silent        - If true load new items without change 'loading' state
	 */
	saveItems: (items: Array<T>, reloadHandler?: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>>) => void;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	validateAll: (skipValidation?: boolean) => Promise<Array<T> | undefined>;
	/**
	 update one item state
	 * @param item         - the new value of the item. result is {...prev, ...item};
	 */
	updateItem: (item: Omit<Partial<T>, 'id'> & WithDeleted) => void;
	/**
	 * delete all items
	 * @param reloadHandler - used to prevent reload if need. by default used 'reload' function
	 */
	deleteAll: (reloadHandler?: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>>) => void;
}

interface LoadData extends BaseParams {
	page?: number;
	pageSize?: number;
	offset?: number;
}

export interface ItemsProviderContext<T extends WithKey, A extends ItemsProviderContextActions<T> = ItemsProviderContextActions<T>> {
	state: ItemsProviderContextState<T>;
	actions: A;
}

export const createItemsProviderContext = once(
	<T extends WithKey, A extends ItemsProviderContextActions<T> = ItemsProviderContextActions<T>>() =>
		React.createContext({} as ItemsProviderContext<T, A>),
);

export const useItemsProviderContext = <T extends WithKey, A extends ItemsProviderContextActions<T> = ItemsProviderContextActions<T>>(required = true)
	: ItemsProviderContext<T, A> => {
	const context = React.useContext<ItemsProviderContext<T, A>>(createItemsProviderContext<T, A>());

	if (required && !context?.actions) throw 'Need ItemsProvider context!';

	return context;
};

/**
 * addGUID - adds the keyGUID property to the elements of the array
 * @param items - Array<WithKey>
 * @returns res
 */
const addGUID = (items: Array<WithKey>) => items.map((item) => ({ ...item, keyGUID: generateGUID() }));

/**
 * defaultTransformFiltersBeforeHandleUrl - transform filters
 * @param filters - BaseParams
 * @returns res
 */
export const defaultTransformFiltersBeforeHandleUrl = (filters) => ({
	...filters,
	page: undefined,
	count: undefined,
	current: undefined,
	showSizeChanger: undefined,
	total: undefined,
	offset: undefined,
	pageSizeOptions: undefined,
	pageSize: filters.pageSize || filters.count,
});

/**
 * ItemsProvider component.
 *
 * usage examples:
 *  - <ItemsProvider type="someType">{React.ReactNode}</ItemsProvider>
 *  - <ItemsProvider type="someType">{(context) => React.ReactNode}</ItemsProvider>
 *  - <ItemsProvider type="someType" render={(item, props) => SingleItem}/>
 *
 * @typeParam T - T Any {WithKey}
 * @param props - ItemsProviderProps
 * @type {React.FC<ItemsProviderProps>}
 * @returns React.ReactElement
 */
export const ItemsProvider: <T extends WithKey>(p: ItemsProviderProps<T>) => React.ReactElement<T> = <T extends WithKey, >({
	items = [],
	type,
	filters = {},
	loadRequest = type.includes('Remote') ? `${type.replace('Remote', '')}ListRemote` : `${type}List`,
	saveRequest = type,
	saveAllRequest = '',
	deleteAllRequest = '',
	children = null as React.ReactNode,
	pagination = {
		current: 1, pageSize: 10, total: 0,
	},
	add = (items) => ({ id: -1 } as T),
	multiple = false,
	validationSchema = undefined,
	clearForSubmit = (item) => item,
	onLoadRequestError,
	onSaveRequestError,
	onValidationError = (key, err, error) => {
		return undefined;
	},
	mode = Mode.View,
	objectKey = '',
	arrayName = '',
	onLoad,
	transformItems = (res, filters) => res,
	unhandledFilters = {},
	transformFiltersBeforeHandleUrl = defaultTransformFiltersBeforeHandleUrl,
	withHandleUrl = false,
	filtersPrefix = '',
	path = '',
	pageInSearch = false,
	defaultFilters = {} as BaseParams,
	afterResetFilters,
	addedFirst = false,
	editItems = undefined,
	syncItems = undefined,
	delay = 0,
	onItemsChange = undefined,
	skipValidationAll = false,
	render = undefined,
	skipInitLoad = true,
	onSave,
	beforeSave,
	onSaveAll,
	otherData = undefined,
	transformAfterSave = (prevItem, editItem, response) => ({ ...prevItem, ...response, ...editItem }),
	searchParamsFromUrl = getSearchParamsFromUrl,
	ttl,
	transformResponse = (res, filters) => res,
	useSyncItemsInsteadHook,
	sliceResultListByPageSize = true,
	sendRequestAtHandleChange = true,
	...rest
}: ItemsProviderProps<T>) => {
	const { openErrorMessage } = useModal();
	const {
		onRequestError = (err) => openErrorMessage?.(err),
	} = rest;
	const ItemsContext = createItemsProviderContext<T>();
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams<{page?: string}>();

	const [edits, _setEdits] = React.useState<Edits<T>>(editItems || {});
	const [innerItems, _setItems] = React.useState<Array<T>>(items ? addGUID(transformItems(items, filters)) as Array<T> : []);
	const _items = useSyncItemsInsteadHook ? syncItems || [] : innerItems;
	const [_otherData, setOtherData] = React.useState<BaseParams>(otherData || {});
	const [loaders, setLoaders] = React.useState<Loaders>({});
	const [loading, setLoading] = React.useState(false);
	const [error, _setError] = React.useState('');
	const [_filters, setFilters] = React.useState<BaseParams>({
		...filters,
		pageSize: pagination.pageSize,
		current: pagination.current,
		...(withHandleUrl ? getKeysByPrefix(searchParamsFromUrl(location, filtersPrefix), filtersPrefix) : {}),
	});
	const lastRequest = React.useRef<Promise<List<T>> | null>(null);
	const [_pagination, setPagination] = React.useState((withHandleUrl
		? {
			...pagination,
			current: pageInSearch ? _filters.page || pagination.current : pagination.current,
			pageSize: _filters.pageSize || _filters.count || pagination.pageSize,
		}
		: {
			...pagination,
		}) as PaginationState);
	const [errors, setErrors] = React.useState<Errors>({});
	const [id, setId] = React.useState<number>(() => {
		if (items) {
			const minId = Math.min(...items.map((item) => item.id));
			if (minId < 0) return minId;
		}
		return -1;
	});
	const [selectedRows, setSelectedRows] = React.useState<Array<T>>([]);
	// const [refs, setRef] = React.useState<any>([]);
	const filtersRef = React.useRef<FiltersRef | null>(null);
	const request = useRequest();
	const requestContext = useRequestProviderContext();
	const deleting = React.useRef<boolean>(false);
	const [controller, setController] = useAbortController();

	const clearError = (items, increment?) =>
		setErrors(Object.keys(items)
			.reduce((acc, key) => ({
				...acc,
				[key]: { err: undefined, submitCount: (errors[key]?.submitCount || 0) + (increment || 0) },
			}), {}));

	const setError = (key, err, increment?) =>
		setErrors((prev) => ({
			...prev,
			[key]: { err, submitCount: (prev[key]?.submitCount || 0) + (increment || 0) },
		}));

	const setEdits = (value) => {
		_setEdits((prev) => {
			const newEdits = typeof value === 'function' ? value(prev) : value;

			clearError(newEdits);

			return newEdits;
		});
	};

	const refs = React.useRef<any>({});
	const setRef = (obj) => {
		refs.current = { ...refs.current, ...obj };
	};

	const initEdits = (items, edits) => {
		let _edits = edits;

		if (mode === Mode.Edit) {
			const temp = {};
			let unsavedItemsCount = 0;

			for (let i = 0; i < items.length; i++) {
				const edit = edits[items[i].id];

				if (!edit) {
					if (items[i].id < 0) {
						items[i].id = -1 - unsavedItemsCount;
						unsavedItemsCount++;
					}
					temp[items[i].id] = items[i];
				} else {
					temp[items[i].id] = { ...edit, ...items[i] };
				}
			}
			setId((prev) => {
				const minId = Math.min(...items.map((item) => item.id));
				if (minId <= prev) return minId - 1;
				return prev;
			});

			_edits = { ..._edits, ...temp };
		} else {
			_edits = {};
		}

		return _edits;
	};

	React.useEffect(() => {
		if (syncItems && !useSyncItemsInsteadHook) {
			_setItems(syncItems);
			setEdits(initEdits(syncItems, edits));
		}
	}, [syncItems]); // syncItems should be memoized or kept in state to avoid endless re-renders

	React.useEffect(() => {
		setEdits(initEdits(!_items.length ? items : _items, editItems || edits));
	}, [mode, Object.keys(editItems || {}).sort().toString()]);

	React.useEffect(() => {
		if (withHandleUrl) {
			const transformedFilters = {
				...transformFiltersBeforeHandleUrl(_filters),
				page: pageInSearch ? _filters.page || _filters.current : undefined,
			};
			const filters = filtersPrefix ? addPrefix(transformedFilters, filtersPrefix) : transformedFilters;

			handleUrl(filters, location, navigate, _filters.current, filtersPrefix, pageInSearch);
		}
	}, [_filters]);

	React.useEffect(() => {
		if (withHandleUrl && path && !params.page && location.search) {
			navigate({
				...location,
				pathname: `/${path.match(/\/d+$/) ? path : `${path}/${_filters.current || ''}`}`,
			}, { replace: true, state: location.state });
		}
	}, [_filters.current, location.search]);

	React.useEffect(() => {
		!_items.length && items?.length && _setItems(items);
		setEdits(initEdits(!_items.length ? items : _items, edits));
	}, []);

	React.useEffect(() => {
		!skipInitLoad && reload(_pagination)
			.catch((err) => (typeof err !== 'string' || !err?.includes('aborted')) && console.log(err));
		return () => {
			controller.abort();
		};
	}, []);

	React.useEffect(() => {
		if (skipInitLoad && items && requestContext?.actions?.updateCache) {
			const pageSize = _pagination.pageSize;
			const offset = _filters.offset || (pageSize !== _pagination.pageSize ? (_filters.current - 1) * +pageSize : 0);
			requestContext.actions.updateCache(
				loadRequest,
				{
					...unhandledFilters,
					..._filters,
					showSizeChanger: undefined,
					offset,
					page: _filters.offset ? undefined : _filters.current,
					current: undefined,
					count: pageSize,
				},
				{
					...otherData,
					list: items,
					count: _pagination?.total,
				},
				ttl,
			);
		}
	}, []);

	const setItems = (items, resetEdits: boolean = true) => {
		_setItems((prev) => {
			const newItems = typeof items === 'function' ? items(prev) : items;
			onItemsChange && onItemsChange(newItems, _filters);

			resetEdits && setEdits((prev) => prev || initEdits(newItems, prev));
			return newItems;
		});
	};

	const updateItem = (item: Omit<Partial<T>, 'id'> & WithDeleted) => {
		setItems((prev) => prev.map((el) => (el.id === item.id ? { ...el, ...item, id: item.id } : el)));
	};

	const resetItems = (items, filters, res?: List<T>, edits?: Edits<T>) => {
		let transformedItems = transformItems(items, filters);
		if (multiple) {
			transformedItems = addedFirst
				? Object.values(edits || {})
					.filter((item) => item.id < 0)
					.sort((a, b) => a.id - b.id)
					.concat(transformedItems)
				: transformedItems
					.concat(
						Object.values(edits || {})
							.filter((item) => item.id < 0)
							.sort((a, b) => b.id - a.id),
					);
		}

		_setItems(transformedItems);
		onItemsChange && onItemsChange(transformedItems, filters, res);
		setEdits(edits || initEdits(transformedItems, {}));
	};

	// "silent" used for load without showing loading

	const load = (
		data: LoadData = { page: 1, pageSize: 10 },
		silent?: boolean,
		concatResult?: boolean,
		resetFilters?: boolean,
		reverseResult?: boolean,
		useResult: {use: boolean} = { use: true },
		edits?: Edits<T>,
	) => {
		const newPageSize = +(data.pageSize || _pagination.pageSize || 10);

		let newFilters: BaseParams = { ..._filters };
		const prevCurrent = newFilters.current;
		resetFilters && Object.keys(_filters).forEach((key) => newFilters[key] = undefined);
		newFilters = {
			...newFilters,
			...(resetFilters ? { ...unhandledFilters, ...defaultFilters } : {}),
			...data,
			current: data.page || 1,
			showSizeChanger: undefined,
			count: _filters.count ? newPageSize : undefined,
		};

		const offset = concatResult
			? _items?.length
			: data.offset || (newPageSize !== _pagination.pageSize ? (newFilters.current - 1) * +newPageSize : 0);

		setFilters(newFilters);
		resetFilters && afterResetFilters && afterResetFilters();

		const promise = request<List<T>>(loadRequest, {
			...unhandledFilters,
			...newFilters,
			offset,
			page: data.offset ? undefined : newFilters.current,
			current: undefined,
			count: newPageSize,
		}, () => !silent && setLoading(true), ttl, controller.signal).then((res) => {
			if (lastRequest.current === promise && useResult.use) {
				!silent && setLoading(false);
				const {
					count, execution, offset, list = [], ...otherData
				} = transformResponse(res, { ...unhandledFilters, ...newFilters });
				const resultItems = concatResult
					? reverseResult ? list.reverse().concat(_items) : _items.concat(list)
					: reverseResult
						? sliceResultListByPageSize ? list.slice(0, newPageSize).reverse() : list.reverse()
						: sliceResultListByPageSize ? list?.slice(0, newPageSize) : list;

				setOtherData(otherData);
				resetItems(addGUID(resultItems), newFilters, res, mode === Mode.Edit ? initEdits(resultItems, edits) : edits);
				_setError('');

				setPagination((prev) => ({
					...prev,
					current: newFilters.current,
					pageSize: newPageSize,
					total: res.count,
				}));
				setSelectedRows(silent ? selectedRows.filter((item: T) => list.find(({ id }) => item.id === id)) : []);

				onLoad && onLoad(res);
				return res;
			}
			if (lastRequest.current === promise && !useResult.use) {
				!silent && setLoading(false);
			}

			return {
				execution: 0,
				count: pagination.total,
				list: _items,
				offset: _filters.offset || (_pagination.pageSize * _pagination.current) - _items.length,
			} as List<T>;
		}).catch((error) => {
			if (typeof error === 'string' && error.includes('aborted')) {
				throw error;
			}

			!silent && setLoading(false);
			_setError(error);

			onLoadRequestError ? onLoadRequestError(error) : onRequestError(error);

			throw error;
		});
		lastRequest.current = promise;
		return promise;
	};

	const loadDelay = (
		data: LoadData = { page: 1, pageSize: 10 },
		silent?: boolean,
		concatResult?: boolean,
		resetFilters?: boolean,
		reverseResult?: boolean,
		useResult?: {use: boolean},
		edits: Edits<T> = {},
	): Promise<List<T>> => {
		if (delay) {
			filtersRef.current = {
				filters: { ...filtersRef.current?.filters, ...data },
				silent,
				concatResult,
				resetFilters,
			};
			setFilters((prev) => ({
				...prev,
				...filtersRef.current?.filters,
				showSizeChanger: undefined,
				count: prev.count,
				pageSize: prev.pageSize,
			}));

			return new Promise((resolve) => {
				setTimeout(resolve, delay);
			})
				.then(() => {
					if (filtersRef.current !== null) {
						const {
							filters, silent, concatResult, resetFilters,
						} = filtersRef.current;
						filtersRef.current = null;
						return load(filters, silent, concatResult, resetFilters, reverseResult, useResult, edits);
					}
					return {
						execution: 0,
						count: _pagination.total,
						list: _items,
						offset: _filters.offset || (_pagination.pageSize * _pagination.current) - _items.length,
					} as List<T>;
				});
		}

		return load(data, silent, concatResult, resetFilters, reverseResult, useResult, edits);
	};

	const loadMore = (data?: LoadData, reverseResult?: boolean, silent?: boolean) => loadDelay(data, silent, true, false, reverseResult);

	const handleChange = (params, sendRequest = sendRequestAtHandleChange) => {
		if (!sendRequest) {
			const { name, value } = params?.currentTarget ?? {};
			setFilters((prev) => ({
				...prev,
				...(params?.currentTarget ? { [name]: value } : params),
				showSizeChanger: undefined,
				count: prev.count,
				pageSize: prev.pageSize,
			}));
			return Promise.resolve({
				execution: 0,
				count: _pagination.total,
				list: _items,
				offset: _filters.offset || (_pagination.pageSize * _pagination.current) - _items.length,
			} as List<T>);
		}

		if (params?.currentTarget) {
			const { name, value } = params.currentTarget;
			return loadDelay({ ..._filters, page: 1, [name]: value });
		}
		return loadDelay({ ..._filters, ...params, page: params?.page ?? 1 });
	};

	const saveItem = (record, requestName = saveRequest) => {
		if (!requestName) {
			throw 'Need saveRequest';
		}

		const item = { ...record, ...clearForSubmit(record) };

		if (refs.current?.[item.id]) {
			/*
			const r = refs[item.id](item);

			item = r.item;

			promises.push(r.validate);
			*/
		}

		setLoaders({ ...loaders, [record.id]: true });

		return request<T>(requestName, item).then((response) => {
			setLoaders({ ...loaders, [record.id]: undefined });

			onSave && onSave(item, response);

			return response;
		}).catch((error: string) => {
			setLoaders({ ...loaders, [record.id]: undefined });

			onSaveRequestError ? onSaveRequestError(error) : onRequestError(error);

			throw error;
		}).finally(() => setPagination((prev) => ({
			...prev,
			..._pagination,
		})));
	};

	const save = (record, skipValidation?: boolean, saveRequest?: string) => {
		const modifiedRecord = { ...record, ...(record.deleted ? record : edits[record.id]) };

		const _saveItem = (record, requestName) => {
			const _editedItem = _items.find((q) => q.id === record.id);
			return beforeSave
				? beforeSave([modifiedRecord], _editedItem ? [_editedItem] : [], () => saveItem(record, requestName), setLoading)
				: saveItem(record, requestName);
		};
		if (!record.deleted && validationSchema && !skipValidation) {
			return validationSchema?.validate(modifiedRecord, { abortEarly: false }).then(() => {
				if (refs.current[modifiedRecord.id]) {
					const r = refs.current[modifiedRecord.id](modifiedRecord);

					return r.validate()
						.then((results) => {
							if (typeof results !== 'undefined') {
								return _saveItem(r.item, saveRequest);
							}
						});
				}
				return _saveItem(modifiedRecord, saveRequest);
			}).catch((err) => {
				if (err.inner) {
					const er = {};
					for (let i = 0; i < err.inner.length; i++) {
						er[err.inner[i].path] = err.inner[i].errors[0];
					}

					setError(record.id, er, 1);

					onValidationError(record.id, er, err.inner);

					if (refs.current[modifiedRecord.id]) {
						const r = refs.current[modifiedRecord.id](modifiedRecord);
						return r.validate();
					}
				} else {
					throw err;
				}
			}) as Promise<T>;
		}

		return _saveItem(modifiedRecord, saveRequest);
	};

	const reload = (p, resetFilters?: boolean) => {
		const page = p?.current || _pagination.current;
		const pageSize = p?.pageSize || _pagination.pageSize;

		return loadDelay({
			...p,
			page: resetFilters ? 1 : page,
			pageSize,
			showSizeChanger: (p || _pagination).showSizeChanger,
		}, false, false, resetFilters);
	};

	const update = (items: Array<T>, resetEdits = true) => {
		setItems(items, resetEdits);
	};

	// const _add = () => add();

	const saveItems = (items, reloadHandler: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>> = reload) => {
		if (saveAllRequest === '') throw 'save all request is not available';

		setLoading(true);

		return request<List<T>>(saveAllRequest, items.map((value) => ({ ...value, ...clearForSubmit(value) })))
			.then((response) => {
				setEdits(mode === Mode.Edit ? initEdits(items, edits) : {});

				onSaveAll && onSaveAll(items, response);
				reloadHandler(_pagination)
					.catch(onSaveRequestError || onRequestError)
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error: string) => {
				setLoading(false);
				onSaveRequestError ? onSaveRequestError(error) : onRequestError(error);
				throw error;
			});
	};

	const deleteItems = (items, reloadHandler: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>> = reload) =>
		saveItems(items.map((value) => ({ id: value.id, deleted: true })), reloadHandler);

	const validateAll = (skipValidation?: boolean, addSubmitCount?: boolean) => {
		const items: Array<T> = [];

		if (validationSchema && !skipValidation) {
			clearError(edits);

			const promises: Array<Promise<ValidationError | undefined>> = [];

			for (const key in edits) {
				const item = edits[key];

				items.push({ ...item, ...clearForSubmit(item) });

				promises.push(validationSchema?.validate(item, { abortEarly: false })
					.catch((err) => {
						if (err.inner) {
							const er = {};
							for (let i = 0; i < err.inner.length; i++) {
								er[err.inner[i].path] = err.inner[i].errors[0];
							}

							setError(key, er, addSubmitCount ? 1 : 0);

							onValidationError(key, er, err);
						} else {
							throw err;
						}
					}));
			}

			if (promises.length > 0) {
				return Promise.all(promises).then((results) => {
					if (!results.some((q) => typeof q === 'undefined')) {
						return Promise.resolve(items);
					}

					// console.log(errors);
				});
			}
		} else {
			for (const key in edits) {
				items.push({ ...edits[key], ...clearForSubmit(edits[key]) });
			}
		}

		return Promise.resolve(items);
	};

	const saveAll = (skipValidation, reloadHandler: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>> = reload) => {
		if (saveAllRequest === '') throw 'save all request is not available';

		if (Object.keys(edits).length <= 0) throw 'There are no edited items';

		const _saveItems = (items) => {
			const _editItems = Object.keys(edits).map((key) => {
				return edits[key];
			});

			return beforeSave
				? beforeSave(_editItems, _items, () => saveItems(items, reloadHandler), setLoading)
				: saveItems(items, reloadHandler);
		};
		const items: Array<T> = [];

		if (validationSchema && !skipValidation) {
			clearError(edits, 1);

			const promises: Array<Promise<ValidationError | undefined>> = [];

			for (const key in edits) {
				let item = edits[key];

				if (refs[item.id]) {
					const r = refs[item.id](item);

					item = r.item;

					promises.push(r.validate());
				}

				items.push({ ...item, ...clearForSubmit(item) });

				promises.push(validationSchema?.validate(item, { abortEarly: false })
					.catch((err) => {
						if (err.inner) {
							const er = {};
							for (let i = 0; i < err.inner.length; i++) {
								er[err.inner[i].path] = err.inner[i].errors[0];
							}

							setError(key, er);

							onValidationError(key, er, err);
						} else {
							throw err;
						}
					}));
			}

			if (promises.length > 0) {
				Promise.all(promises).then((results) => {
					if (!results.some((q) => typeof q === 'undefined')) {
						return _saveItems(items.sort((a, b) => (addedFirst ? 1 : -1) * (a.id - b.id)));
					}
				});
			}
		} else {
			for (const key in edits) {
				items.push({ ...edits[key], ...clearForSubmit(edits[key]) });
			}

			return _saveItems(items.sort((a, b) => (addedFirst ? 1 : -1) * (a.id - b.id)));
		}
	};

	const deleteAll = (reloadHandler: (p?: LoadData, resetFilters?: boolean) => Promise<List<T>> = reload) => {
		if (deleteAllRequest === '') throw 'delete all request is not available';

		setLoading(true);

		return request<List<T>>(deleteAllRequest, { ...unhandledFilters, ..._filters })
			.then((response) => {
				setEdits({});

				reloadHandler({})
					.catch(onSaveRequestError || onRequestError)
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error: string) => {
				setLoading(false);
				onSaveRequestError ? onSaveRequestError(error) : onRequestError(error);
				throw error;
			});
	};

	const setEdit = (record) => {
		setEdits({ ...edits, [record.id]: record });
	};

	const addItem = (item?: Partial<T>) => {
		setId((prev) => prev - 1);

		const newItem = item ? { ...item, id } as T : { ...add(_items), id };

		update(addedFirst ? [newItem].concat(_items) : _items.concat(newItem));

		setEdit(newItem);
		return newItem;
	};

	const addRef = (key, f) => {
		setRef({ [key]: f });
	};

	const value = {
		state: {
			items: _items,
			loading,
			pagination: _pagination,
			filters: _filters,
			edits,
			errors,
			loaders,
			multiple,
			type,
			selectedRows,
			addedFirst,
			saveRequest,
			otherData: _otherData,
			transformAfterSave,
			deleting,
			deleteAllRequest,
			error,
			saveAllRequest,
		},
		actions: {
			load: loadDelay,
			save,
			saveAll,
			update,
			add: addItem,
			setEdits,
			setErrors,
			reload,
			setEdit,
			setItems,
			addRef,
			setSelectedRows,
			deleteItems,
			handleChange,
			loadMore,
			saveItems,
			setLoading,
			validateAll,
			updateItem,
			deleteAll,
		},
	};

	const context = useItemsProviderContext<WithKey>(false);

	if (context.state) {
		// ItemsProvider in ItemsProvider

		React.useEffect(() => {
			objectKey && arrayName && context.actions.addRef(objectKey, (item) => {
				const items: Array<T> = [];

				for (const key in edits) {
					items.push({ ...edits[key], ...clearForSubmit(edits[key]) });
				}

				return { item: { ...item, [arrayName]: items }, validate: () => validateAll(false, true) };
			});
		}, [edits, context?.state?.edits]);
	} else {
		React.useEffect(() => {
			!skipValidationAll && validateAll(false);
		}, [edits]);
	}

	return (
		<ItemsContext.Provider value={value}>
			{render && <React.Fragment key="renderItems">
				{_items.map((item) => render(item, {
					loading,
					reload,
					add: addItem,
					update: (newItem) => updateItem({ ...newItem, id: (item.id || newItem.id) as number }),
					save: (values, saveRequest) => save(values, false, saveRequest),
					updateAndSave: (values, saveRequest) => save({ ...item, ...values, id: item.id }, false, saveRequest),
				}))}
			</React.Fragment>}
			{typeof children === 'function' ? children(value) : children}
		</ItemsContext.Provider>
	);
};
