import * as React from 'react';

import { FieldProps, ErrorMessage, getIn } from 'formik';

export type FormikInputRenderFunc = (fieldProps: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) => React.ReactElement;

export interface FormikInputProps {
	fieldProps: FieldProps;
	containerClassName?: string;
	render?: FormikInputRenderFunc;
	title?: React.ReactNode;
	inputId?: string;
	showValidateAfterSubmit?: boolean;
	inputProps?: React.HTMLProps<HTMLInputElement>;
	ErrorComponent?: React.FC<{error: string}>;
	withValidIndicator?: boolean;
	customValidCondition?: (errors, touched, value, form) => boolean;
}

export interface ValidFieldWrapperProps {
	fieldName: string;
	form: FieldProps['form'];
	customValidCondition?: (errors, touched, value, form) => boolean;
}

export const ValidFieldWrapper: React.FC<ValidFieldWrapperProps> = ({
	fieldName,
	form,
	children,
	customValidCondition = (errors, touched, value, form) => !errors && value,
}) => {
	const errors = getIn(form.errors, fieldName);
	const touched = getIn(form.touched, fieldName);
	const value = getIn(form.values, fieldName);

	return <>
		{customValidCondition(errors, touched, value, form) ? <div className="valid-field" /> : null}
		{children}
	</>;
};

const defaultRender = ({ form, field }: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
	<input className="form-control" type="text" id={field.name} {...field} {...inputProps} />;

export const defaultErrorComponent: React.FC<{error: string | object}> = ({ error }) =>
	<div className="validation-message">
		{typeof error === 'string' ? error : Object.keys(error)
			.filter((key) => typeof error[key] === 'string')
			.map((key) => error[key])
			.join(', ')}
	</div>;

export const FormikInput: React.FC<FormikInputProps> = ({
	fieldProps,
	containerClassName = 'form-group col-sm-6',
	render = defaultRender,
	title,
	inputId,
	showValidateAfterSubmit = true,
	inputProps,
	ErrorComponent = defaultErrorComponent,
	withValidIndicator,
	customValidCondition,
}) => {
	const { form, field } = fieldProps;

	return <div className={`${containerClassName}`}>
		{title && <label htmlFor={inputId || field.name}>{title}</label>}
		<div className="is-relative">
			{(showValidateAfterSubmit ? form.submitCount > 0 : true) && (
				<ErrorMessage
					name={field.name}
					render={(msg) => <ErrorComponent error={msg} />}
				/>
			)}
			{withValidIndicator ? <ValidFieldWrapper customValidCondition={customValidCondition} form={form} fieldName={field.name} /> : null}
			{render(fieldProps, inputProps)}
		</div>
	</div>;
};
