import React from 'react';
import { useLocation } from 'react-router-dom';

import GA4React from 'ga-4-react';

interface RouteChangeTrackerProps {
	id: string;
}

const RouteChangeTracker: React.FC<RouteChangeTrackerProps> = ({ id, children }) => {
	const ga4Ref = React.useRef<any>(null);
	const location = useLocation();

	React.useEffect(() => {
		if (process.env.NODE_ENV === 'production' && id && id.trim() !== '') {
			const ga4react = new GA4React(id);

			ga4react.initialize().then((ga4) => {
				ga4.pageview(window.location.pathname + window.location.search);

				ga4Ref.current = ga4react;
			}, console.error);
		}
	}, []);

	React.useEffect(() => {
		if (ga4Ref.current && GA4React.isInitialized()) {
			ga4Ref.current.pageview(location.pathname + location.search);
			ga4Ref.current.gtag('set', 'page', location.pathname);
		}
	}, [location]);

	return <>{children}</>;
};

export default RouteChangeTracker;
