import * as React from 'react';

import { useReduxContext } from '@app/hooks/useReduxContext';

interface Props {
	className?: string;
	svgIconFill?: string;
	locationId?: number;
	icons?: Array<{key: string, icon: React.ReactNode, title: string }>;
	socialMedias?: Array<{ link: string }>;
}

const getRSLogo = (fill: string = '#342e20') => {
	return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 25" id="appIcon">
		<path
			d="M22.7,22.5c-2.6,0-5.4-0.9-7.6-2.5l2.4-3.5c1.3,1.1,3.3,1.9,5.3,1.9c1.5,0,2.5-0.7,2.5-1.7
			c0-1.2-1.6-1.8-3.4-2.4c-2.6-0.9-5.9-2-5.9-5.6c0-2.2,1.2-3.5,2.2-4.3c1.3-1,3.2-1.6,5.1-1.6c2.5,0,5.2,1,6.4,1.9l-2.3,3.6
			c-0.7-0.5-2.1-1.3-3.7-1.3c-1.6,0-2.7,0.7-2.7,1.6c0,1,1.4,1.5,3.1,2c2.8,0.9,6.2,2.1,6.2,6.1c0,1.7-0.8,3.2-2.3,4.3
			C26.6,22,24.8,22.5,22.7,22.5L22.7,22.5z"
			fill={fill}
		/>
		<path
			d="M35.1,22.5c-1.8,0-3.3-1.5-3.4-3.3c0,0,0,0,0-0.1c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.3c0,0,0,0,0,0.1
			C38.5,21.1,37,22.5,35.1,22.5"
			fill={fill}
		/>
		<path
			d="M1.5,22.1h5V3.1h-5V22.1z M11.3,9.5C9.4,9.5,7.9,8,7.9,6.2c0,0,0,0,0-0.1c0-1.9,1.5-3.4,3.4-3.4
			c1.9,0,3.4,1.5,3.4,3.3c0,0,0,0,0,0.1C14.7,8,13.2,9.5,11.3,9.5"
			fill={fill}
		/>
	</svg>;
};

const defaultIcons = [
	{
		key: 'facebook',
		icon: <i className="fa fa-facebook" />,
		title: 'facebook link',
	},
	{
		key: 'instagram',
		icon: <i className="fa fa-instagram" />,
		title: 'instagram link',
	},
	{
		key: 'realself',
		icon: <i className="fa fa-facebook" />,
		title: 'realself link',
	},
	{
		key: 'twitter',
		icon: <i className="fa fa-twitter" />,
		title: 'twitter link',
	},
];

const SocialIcons: React.FC<Props> = ({
	className, svgIconFill, locationId, icons = defaultIcons, socialMedias: socialMediasProps,
}) => {
	const { offices } = useReduxContext();
	const socialMedias = socialMediasProps
		|| (locationId ? offices?.find((item) => item.id === locationId)?.socialMedias : offices?.[0]?.socialMedias);
	return <div className={className}>
		{socialMedias?.filter((item) => item.link).map((media) => {
			const iconData = icons.find((item) => media.link.includes(item.key));
			if (!iconData) return null;

			return <a
				key={media.link}
				href={media.link}
				target="_blank"
				title={iconData.title}
				aria-label={iconData.title}
				rel="noreferrer"
				className={media.link.includes('realself') ? 'realself' : ''}
			>
				{media.link.includes('realself') ? getRSLogo(svgIconFill) : iconData.icon}
			</a>;
		})}
	</div>;
};

export default SocialIcons;
