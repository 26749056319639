import React from 'react';
import { createPortal } from 'react-dom';

import { generateGUID } from '@common/react/utils/utils';

import '@common/react/scss/components/base/messages.scss';

export enum MessageType {
	Error,
	Success,
	Warning,
}

const icons = {
	[MessageType.Error]: <i className="fa fa-info-circle" style={{ color: 'red' }} />,
	[MessageType.Success]: <i className="fa fa-circle" style={{ color: 'green' }} />,
	[MessageType.Warning]: <i className="fa fa-warning" style={{ color: '#e0c702' }} />,
};

interface MessageProps {
	text: string;
	type: MessageType;
	delay: number;
	afterRemove: () => void;
}

interface MessagesProps {
	render: (addMessage: (text, options?: { type, delay? }) => void) => React.ReactNode;
}

const Message: React.FC<MessageProps> = (props) => {
	const {
		text,
		type,
		delay = 8000,
		afterRemove,
	} = props;
	React.useEffect(() => {
		setTimeout(() => {
			afterRemove();
		}, delay > 0 ? delay : 8000);
	}, []);

	return <div className="message">
		{icons[type]}
		{' '}
		{text}
	</div>;
};

const Messages: React.FC<MessagesProps> = ({ render }) => {
	const [container, setContainer] = React.useState<HTMLElement | null>();
	const [messages, setMessages] = React.useState<Array<{ text, delay, id, type }>>([]);

	React.useEffect(() => {
		if (!container) {
			setContainer(document.getElementById('react-app'));
		}
		return () => {
			if (typeof document === 'undefined') return;
			const elem = document.body;
			elem.style.overflowY = 'auto';
		};
	}, []);

	const addMessage = (text, options) => {
		const { type = MessageType.Error, delay = 8000 } = options || {};
		setMessages((prev) => prev.concat({
			text,
			type,
			delay,
			id: generateGUID(),
		}));
	};

	const removeMessage = (id) => {
		setMessages((prev) => prev.filter((item) => item.id !== id));
	};

	return <>
		{container && createPortal(<div className="messages-container">
			{messages.map((item) => <Message
				key={item.id}
				text={item.text}
				type={item.type}
				delay={item.delay}
				afterRemove={() => removeMessage(item.id)}
			/>)}
		</div>, container)}
		{render(addMessage)}
	</>;
};

export default Messages;
