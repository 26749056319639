import * as React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import Layout from '@app/components/Layout';
import { MainRoute } from '@app/components/Routes/MainRoute';

const paramsTranslated = {
	fallback: <Loader />,
};

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */
		'@app/components/Pages/Home/Home'
	)), paramsTranslated);
const Contacts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactsPage" */
		'@app/components/Pages/Contacts/Contacts'
	)), paramsTranslated);
const Section = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SectionPage" */
		'@app/components/Pages/Section/Section'
	)), paramsTranslated);
const DoctorList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorListPage" */
		'@app/components/Pages/DoctorList/DoctorList'
	)), paramsTranslated);
const BeforeAfter = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BeforeAfterPage" */
		'@app/components/Pages/BeforeAfterList/BeforeAfter'
	)), paramsTranslated);
const DoctorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorPage" */
		'@app/components/Pages/DoctorList/DoctorPage'
	)), paramsTranslated);
const ClinicPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPage" */
		'@app/components/Pages/Clinic/ClinicPage'
	)), paramsTranslated);
const BlogPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BlogPage" */
		'@app/components/Pages/Blog/Blog'
	)), paramsTranslated);
const FaqListPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqListPage" */
		'@app/components/Pages/Faq/Faq'
	)), paramsTranslated);
const SingleFaq = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SingleFaqPage" */
		'@app/components/Pages/Faq/FaqPage'
	)), paramsTranslated);
const Specials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specials" */
		'@app/components/Pages/Specials/Specials'
	)), paramsTranslated);
const Sale = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Sale" */
		'@app/components/Pages/Sale/Sale'
	)), paramsTranslated);

export const baseRoutes = (
	<>
		<Route
			path="/"
			element={<MainRoute />}
		>
			<Route path="/" element={<Home />} />
			<Route path="/es" element={<Home />} />
			<Route path="/home" element={<Home />} />
			<Route path="/es/home" element={<Home />} />
			<Route path="/contacts" element={<Contacts />} />
			<Route path="/es/contacts" element={<Contacts />} />
			<Route path="/es/contactos" element={<Contacts />} />
			<Route path="/specials/:page?" element={<Specials />} />
			<Route path="/es/specials/:page?" element={<Specials />} />
			<Route path="/doctors" element={<DoctorList />} />
			<Route path="/es/doctors" element={<DoctorList />} />
			<Route path="/practica/doctors "element={<DoctorList />} />
			<Route path="/practice/doctors "element={<DoctorList />} />
			<Route path="/es/practica/doctors" element={<DoctorList />} />
			<Route path="/es/practice/doctors" element={<DoctorList />} />
			<Route path="/beforeafter" element={<BeforeAfter />} />
			<Route path="/es/beforeafter" element={<BeforeAfter />} />
			<Route path="/es/doctor/:path?" element={<DoctorPage />} />
			<Route path="/doctor/:path?" element={<DoctorPage />} />
			<Route path="/clinic/:path?" element={<ClinicPage />} />
			<Route path="/es/clinic/:path?" element={<ClinicPage />} />
			<Route path="/faq" element={<FaqListPage />} />
			<Route path="/faq/:page" element={<SingleFaq />} />
			<Route path="/es/faq" element={<FaqListPage />} />
			<Route path="/es/faq/:page" element={<SingleFaq />} />
			<Route path="/blog" element={<BlogPage />} />
			<Route path="/es/blog" element={<BlogPage />} />
			<Route path="/es/sale/:path" element={<Sale />} />
			<Route path="/sale/:path" element={<Sale />} />
			<Route path="/:path/*" element={<Section />} />
		</Route>
		<Route path="*" element={<NotFoundRoute openRoute={MainRoute} />} />
	</>
);

export const clientRoutes = (<Route
	element={<Layout>
		<Outlet />
	</Layout>}
>
	{baseRoutes}
</Route>);

export const routes = (<Layout>
	<Routes>
		{baseRoutes}
	</Routes>
</Layout>);
