import * as React from 'react';

import once from 'lodash/once';

import { BaseParams } from '@common/react/objects/BaseParams';

export interface IModal {
	onClose?: VoidFunction;
	visible?: boolean;
}

export interface AlertProps extends IModal {
	title?: string;
	content?: string;
	onOk?: VoidFunction;
}

export interface ConfirmProps extends IModal {
	title?: string;
	content?: string;
	onOk?: VoidFunction;
	onCancel?: VoidFunction;
}

export type OpenModal<T> = (params: T) => void;

interface IModalContext<T extends BaseParams = BaseParams, CType extends BaseParams = BaseParams> {
	openAlert: OpenModal<AlertProps & T>;
	openConfirm: OpenModal<ConfirmProps & CType>;
	openDeleteConfirm: OpenModal<ConfirmProps & CType>;
	openError: OpenModal<AlertProps>;
	openSuccess: OpenModal<AlertProps>;
	renderModal: (visible: boolean, close: (resetProps?: boolean) => void, props: BaseParams) => React.ReactNode;
	openErrorMessage?: (message: string, params?) => void;
	openSuccessMessage?: (message: string, params?) => void;
}

export const createModalContext = once(<T extends BaseParams, CType extends BaseParams>() => React.createContext({} as IModalContext<T, CType>));

export const useModal = <
	T extends BaseParams = BaseParams,
	CType extends BaseParams = BaseParams,
>() => React.useContext<IModalContext<T, CType>>(createModalContext<T, CType>());

const useDefaultModalLogic = <T extends BaseParams = BaseParams>() => {
	const [visible, setVisible] = React.useState(false);
	const [props, setProps] = React.useState<T | undefined>();

	const openModal = React.useCallback((props?: T) => {
		setProps(props);
		setVisible(true);
	}, []);

	const closeModal = React.useCallback((resetProps: boolean = true) => {
		resetProps && setProps(undefined);
		setVisible(false);
	}, []);

	const updateProps = (props) => {
		setProps((prev) => (prev ? { ...prev, ...props } : props));
	};

	return {
		visible,
		props,
		openModal,
		updateProps,
		closeModal,
	};
};

interface ModalContextProviderProps<AlertProps extends BaseParams = BaseParams, ConfirmProps extends BaseParams = BaseParams> {
	renderAlert: (visible: boolean, close: VoidFunction, props: AlertProps) => React.ReactNode;
	renderSuccess: (visible: boolean, close: VoidFunction, props: BaseParams) => React.ReactNode;
	renderError: (visible: boolean, close: VoidFunction, props: BaseParams) => React.ReactNode;
	renderConfirm: (visible: boolean, close: VoidFunction, props: ConfirmProps) => React.ReactNode;
	renderModal: (visible: boolean, close: (resetProps?: boolean) => void, props: BaseParams) => React.ReactNode;
	renderDeleteConfirm: (visible: boolean, close: VoidFunction, props: ConfirmProps) => React.ReactNode;
	children?: React.ReactNode;
	openErrorMessage?: (message: string, params: any) => void;
	openSuccessMessage?: (message: string, params: any) => void;
}

export const ModalContextProvider = <IAlertProps extends AlertProps = AlertProps, IConfirmProps extends ConfirmProps = ConfirmProps>({
	children,
	renderAlert,
	renderConfirm,
	renderDeleteConfirm,
	renderModal,
	renderError,
	renderSuccess,
	openErrorMessage,
	openSuccessMessage,
}: ModalContextProviderProps<IAlertProps, IConfirmProps>) => {
	const ModalContext = createModalContext<IAlertProps, IConfirmProps>();
	const {
		openModal: openAlert,
		closeModal: closeAlert,
		props: alertProps,
		visible: alertVisible,
	} = useDefaultModalLogic<IAlertProps>();
	const {
		openModal: openSuccess,
		closeModal: closeSuccess,
		props: successProps,
		visible: successVisible,
	} = useDefaultModalLogic<BaseParams>();
	const {
		openModal: openError,
		closeModal: closeError,
		props: errorProps,
		visible: errorVisible,
	} = useDefaultModalLogic<BaseParams>();
	const {
		openModal: openConfirm,
		closeModal: closeConfirm,
		props: confirmProps,
		visible: confirmVisible,
	} = useDefaultModalLogic<IConfirmProps>();
	const {
		openModal: openDeleteConfirm,
		closeModal: closeDeleteConfirm,
		props: deleteConfirmProps,
		visible: deleteConfirmVisible,
	} = useDefaultModalLogic<IConfirmProps>();

	const modalContextValue: IModalContext<IAlertProps, IConfirmProps> = {
		openAlert,
		openConfirm,
		renderModal,
		openError,
		openSuccess,
		openErrorMessage,
		openSuccessMessage,
		openDeleteConfirm,
	};

	return (
		<ModalContext.Provider value={modalContextValue}>
			{alertProps && (
				renderAlert(alertVisible, closeAlert, alertProps)
			)}
			{errorProps && (
				renderError(errorVisible, closeError, errorProps)
			)}
			{successProps && (
				renderSuccess(successVisible, closeSuccess, successProps)
			)}
			{confirmProps && (
				renderConfirm(confirmVisible, closeConfirm, confirmProps)
			)}
			{deleteConfirmProps && (
				renderDeleteConfirm(deleteConfirmVisible, closeDeleteConfirm, deleteConfirmProps)
			)}
			{children}
		</ModalContext.Provider>
	);
};
