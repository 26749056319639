/**
 * ## ItemsProviderWithStore.tsx ##
 * This file contains ItemsProviderWithStore component
 * @packageDocumentation
 */
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TypeKeys, ItemsState } from '@common/react/store/ItemsProviderStore';

import {
	ItemsProvider,
	ItemsProviderProps,
	useItemsProviderContext,
	WithKey,
	ItemsProviderContextState,
	SortingDirection,
	ItemsProviderContextActions,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { equal } from '@common/typescript/Utils';
import AdvancedItemsProvider from '@common/react/components/Core/AdvancedItemsProvider/AdvancedItemsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

interface ItemsHandlerProps<T extends WithKey> extends ItemsProviderProps<T> {
	children: React.ReactNode | ((state: ItemsProviderContextState<T>, actions: ItemsProviderWithStoreActions<T>) => React.ReactNode);
	storeName: string;
	initLoad: boolean;
	storeData: ItemsState<T>;
}

export interface ItemsProviderWithStoreActions<T extends WithKey> extends ItemsProviderContextActions<T> {
	getByIdOrFirst: (id?: number) => T | undefined;
}

export interface ItemsProviderWithStoreProps<T extends WithKey> extends ItemsProviderProps<T> {
	/**
	 * - 1. ReactElement to be wrapped in an ItemsProviderWithStore context
	 * - 2. function with ItemsProviderWithStore state as first argument
	 */
	children: React.ReactNode | ((state: ItemsProviderContextState<T>, actions: ItemsProviderWithStoreActions<T>) => React.ReactNode);
	/**
	 * property in redux that stores data for ItemsProviderWithStore.
	 */
	storeName: string;
	/**
	 * default element sorting. Used to define the position of a new element.
	 *
	 * (only for AdvancedItemsProvider)
	 *
	 * If defaultSort and filterHandler are present, AdvancedItemsProvider will be used.
	 */
	defaultSort?: [string, SortingDirection];
	/**
	 * default element sorting. Determines whether a new element should be displayed with the current filters
	 *
	 * (only for AdvancedItemsProvider)
	 *
	 * If defaultSort and filterHandler are present, AdvancedItemsProvider will be used.
	 */
	filterHandler?: (item, filters) => boolean | Promise<boolean>;
	/**
	 * Custom sorting of elements after adding a new one.
	 *
	 * (only for AdvancedItemsProvider)
	 *
	 * If defaultSort and filterHandler are present, AdvancedItemsProvider will be used.
	 */
	sortHandler?: (item1, item2, filters) => number;
	/**
	 * init load option
	 */
	skipInitLoad?: boolean;
	/**
	 * the value from the store will be used directly and not just the starting state
	 */
	syncStoreItems?: boolean;
}

const getParamsFromItemProvider = (filters, pagination) => {
	return {
		...filters,
		...{
			count: pagination?.pageSize || 10,
			current: filters?.page || pagination?.current || 1,
			pageSize: undefined,
			page: undefined,
		},
	};
};

const getByIdOrFirst = (id, items) => {
	return id ? items?.find((item) => item.id === id) ?? items[0] : items[0];
};

const ItemsHandler = <T extends WithKey>({
	storeName, initLoad, storeData, ...p
} : ItemsHandlerProps<T>) => {
	const { children } = p;

	const context = useItemsProviderContext<T>();

	const {
		state: {
			items,
			filters,
			pagination,
			error,
		},
		actions: { load },
	} = context;
	const [initUpdate, setInitUpdate] = React.useState(true);

	const dispatch = useDispatch();

	React.useEffect(() => {
		if (initLoad) {
			load({})
				.catch((error) => {
					if (typeof error === 'string' && error.includes('aborted')) {
						return;
					}
					load({})
						.catch((e) => {
							console.log(e);
						});
				});
		}
	}, []);

	React.useEffect(() => {
		if ((!initUpdate || (storeData?.isEmpty && !initLoad))) {
			dispatch({
				items,
				params: getParamsFromItemProvider({ ...p.unhandledFilters, ...filters }, pagination),
				type: TypeKeys.SETITEMS,
				storageName: storeName,
				objectType: p.type,
				total: pagination.total || items?.length,
				current: pagination.current,
				isEmpty: !!error,
			});
		}
		setInitUpdate(false);
	}, [items, error]);

	return (
		<>
			{typeof children === 'function'
				? children(context.state, { ...context.actions, getByIdOrFirst: (id) => getByIdOrFirst(id, items) }) : children}
		</>
	);
};

/**
 * ItemsProviderWithStore component.
 *
 * usage examples:
 *  - <ItemsProviderWithStore type="someType">{React.ReactNode}</ItemsProvider>
 *  - <ItemsProviderWithStore type="someType">{(state, actions) => React.ReactNode}</ItemsProvider>
 *  - <ItemsProviderWithStore type="someType" render={(item, props) => SingleItem}/>
 *
 * if you need to use the AdvancedItemsProvider, you must provide a defaultSort and filterHandler.
 *  - <ItemsProviderWithStore type="someType" defaultSort={['sortOrder', SortingDirection.Ascending]}
 *  filterHandler={() => false}>{(state, actions) => React.ReactNode}</ItemsProvider>
 *
 * @typeParam T - T Any {WithKey}
 * @param props - ItemsProviderWithStoreProps
 * @type {React.FC<ItemsProviderWithStoreProps>}
 * @returns React.ReactElement
 */
const ItemsProviderWithStore = <T extends WithKey>(props: ItemsProviderWithStoreProps<T>) => {
	const { children, syncStoreItems } = props;
	const {
		storeName, defaultSort, filterHandler, sortHandler, skipInitLoad = false, ...p
	} = props;
	const store = useSelector((state) => state[storeName], shallowEqual) as ItemsState<T>;
	const context = useItemsProviderContext<T>(false);
	const loading = context?.state?.type === p.type && (context?.state?.loading || store?.isEmpty);
	const dispatch = useDispatch();

	const itemsData = React.useMemo(() => {
		const data = { items: p.items ?? store.items, pagination: p.pagination, fromStore: skipInitLoad };
		if (!store || skipInitLoad) {
			return data;
		}
		const currentParams = getParamsFromItemProvider({ ...p.unhandledFilters, ...p.filters }, p.pagination);
		const storeParams = { ...store.params, count: store.params.count || 10, current: store.params.current || 1 };

		return currentParams.count === storeParams.count && currentParams.count === storeParams.count
		&& equal(
			{ ...currentParams, count: undefined, current: undefined },
			{ ...storeParams, count: undefined, current: undefined },
		)
			? { items: p.items || store.items, pagination: p.pagination || store.pagination, fromStore: !store.isEmpty || !!p.items } : data;
	}, [!loading]);

	if (loading) {
		return <Loader />;
	}

	const onItemsChange = (items, filters, res) => {
		if (!syncStoreItems) return;
		dispatch({
			items,
			params: getParamsFromItemProvider(filters, { current: filters?.current }),
			type: TypeKeys.SETITEMS,
			storageName: storeName,
			objectType: p.type,
			total: res?.count || items?.length,
			current: filters?.current,
			isEmpty: false,
		});
	};

	return (
		!!defaultSort && !!filterHandler
			? (
				<AdvancedItemsProvider<T>
					defaultSort={defaultSort}
					filterHandler={filterHandler}
					sortHandler={sortHandler}
					{...p}
					items={itemsData.items}
					useSyncItemsInsteadHook={syncStoreItems}
					syncItems={syncStoreItems ? store.items : undefined}
					onItemsChange={onItemsChange}
					skipInitLoad={syncStoreItems ? !!(itemsData.fromStore || p.items) : undefined}
					pagination={{ ...itemsData.pagination, current: itemsData.pagination?.current || 1 }}
				>
					{syncStoreItems
						? ((context) => (typeof children === 'function' ? (children as any)(
							context.state,
							{ ...context.actions, getByIdOrFirst: (id) => getByIdOrFirst(id, context.state.items) },
						) : children))
						: <ItemsHandler storeData={store} {...p} storeName={storeName} initLoad={!(itemsData.fromStore || p.items)}>
							{children}
						</ItemsHandler>}
				</AdvancedItemsProvider>
			)
			: (
				<ItemsProvider<T>
					{...p}
					items={itemsData.items}
					pagination={itemsData.pagination}
					onItemsChange={onItemsChange}
					useSyncItemsInsteadHook={syncStoreItems}
					syncItems={syncStoreItems ? store.items : undefined}
					skipInitLoad={syncStoreItems ? !(itemsData.fromStore || p.items) : undefined}
				>
					{syncStoreItems
						? ((context) => (typeof children === 'function' ? (children as any)(
							context.state,
							{ ...context.actions, getByIdOrFirst: (id) => getByIdOrFirst(id, context.state.items) },
						) : children))
						: <ItemsHandler storeData={store} {...p} storeName={storeName} initLoad={!(itemsData.fromStore || p.items)}>
							{children}
						</ItemsHandler>}
				</ItemsProvider>
			)
	);
};

export default ItemsProviderWithStore;
