import * as React from 'react';

import { I18nextProvider } from 'react-i18next';

import { Lang } from '@common/typescript/objects/Lang';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { initI18n } from '@app/i18n';

const InitI18n: React.FC = ({ children }) => {
	const { getLang } = useApplicationContext();
	const lang = getLang();
	return <I18nextProvider i18n={initI18n(lang === Lang.Es ? 'es' : 'en')}>
		{children}
	</I18nextProvider>;
};

export default InitI18n;
